export default function (value: number): string {
  switch (value) {
    case 1:
      return 'Parcel';
    case 2:
      return 'Farm';
    case 3:
      return 'Unit';
    case 4:
      return 'Large Scale';
    default:
      return 'Unit';
  }
}
