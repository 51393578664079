






















































































import CsvTable from '@/components/CsvTable.vue';
import EditableCell from '@/components/EditableCell.vue';
import { SatAnalyticName } from '@/enums/satanalytic';
import { SatSurvey, SatSurveyStatus } from '@/interfaces/satsurvey';
import API from '@/services/api';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';

export interface FilterOption {
  text: string;
  value: string;
}

const columnsSatSurveys = [
  {
    title: 'Organization',
    dataIndex: 'organizationName',
    key: 'organizationName',
    width: 120
  },
  {
    title: 'Product',
    dataIndex: 'analyticType',
    key: 'analyticType',
    width: 85,
    filters: [
      { text: 'weeds', value: 'weeds' },
      { text: 'nematodes', value: 'nematodes' },
      { text: 'ndvi', value: 'ndvi' },
      { text: 'flowering', value: 'flowering' },
      { text: 'sugar', value: 'sugar' },
      { text: 'land-use', value: 'land-use' },
      { text: 'deforestation', value: 'deforestation' },
      { text: 'crop-classification', value: 'crop-classification' }
    ],
    onFilter: (value, record) => record.analyticType === value
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    filters: [],
    onFilter: (value, record) => record.source === value,
    width: 80,
    scopedSlots: { customRender: 'source' }
  },
  {
    title: 'Unit',
    dataIndex: 'unitName',
    key: 'unitName',
    filters: [],
    onFilter: (value, record) => record.unitName === value,
    width: 100
  },
  {
    title: 'Farm',
    dataIndex: 'farmName',
    key: 'farmName',
    width: 100
  },
  {
    title: 'Processing Type',
    dataIndex: 'processingType',
    key: 'processingType',
    scopedSlots: { customRender: 'processingType' },
    width: 100
  },
  {
    title: 'Last Processing Date',
    dataIndex: 'processingStartTime',
    key: 'processingStartTime',
    scopedSlots: { customRender: 'processingStartTime' },
    sorter: (a, b) => {
      const { processingStartTime: bProcessingStartTime = new Date().toString() } = b;
      const { processingStartTime: aProcessingStartTime = new Date().toString() } = a;

      return new Date(bProcessingStartTime).getTime() - new Date(aProcessingStartTime).getTime();
    },
    defaultSortOrder: 'ascend',
    width: 100
  },
  {
    title: 'Last automatic update',
    dataIndex: 'date',
    key: 'date',
    width: 90
  },
  {
    title: 'Season',
    dataIndex: 'season',
    key: 'season',
    width: 80
  },
  {
    title: 'Area',
    dataIndex: 'area',
    key: 'area',
    scopedSlots: { customRender: 'area' },
    sorter: (a, b) => a.area - b.area,
    width: 80
  },
  {
    title: 'Progress',
    dataIndex: 'progress',
    key: 'progress',
    scopedSlots: { customRender: 'progress' },
    width: 100
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    filters: [
      { text: 'Processing', value: SatSurveyStatus.Processing },
      { text: 'Published', value: SatSurveyStatus.Published }
    ],
    onFilter: (value: string, record: SatSurvey): boolean => record.status.indexOf(value) === 0
  },

  {
    title: 'OK/Skipped/Failed/Total',
    width: 150,
    scopedSlots: { customRender: 'successful/skipped/failed/total' }
  },
  {
    title: 'Processing Time',
    dataIndex: 'processingTime',
    key: 'processingTime',
    scopedSlots: { customRender: 'processingTime' },
    width: 80
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    scopedSlots: { customRender: 'notes' },
    width: 90
  }
];

@Component({ components: { EditableCell, CsvTable } })
export default class SatSurveysTable extends Vue {
  private columnsSatSurveys = columnsSatSurveys;
  satSurveyStatus = SatSurveyStatus;
  modalVisible = false;
  selectedSatSurveyData = null;
  selectedSatSurvey = null;

  get dataSource(): SatSurvey[] {
    return this.$store.state.satsurveys.filteredData;
  }

  get modalWidth(): number {
    return window.innerWidth - 100 || 520;
  }

  get unitNameFilterOptions(): FilterOption[] {
    return this.$store.getters['satsurveys/getUnitFilterOptions'].map((unitName) => ({
      text: unitName,
      value: unitName
    }));
  }

  mounted(): void {
    this.loadData();
  }

  get from() {
    return this.$store.state.fromSatSurveys;
  }

  onFromChange(date) {
    this.$store.dispatch('setSatSurveysFrom', date);
    this.loadData();
  }
  getStatus(satsurvey: SatSurvey): string {
    if (satsurvey.status === SatSurveyStatus.Processing) return 'active';
    return satsurvey.failed ? 'exception' : 'success';
  }

  disabledFromDate(date) {
    return date && date > moment.utc().startOf('day');
  }

  async loadData(): Promise<void> {
    const fromDate = this.from?.utc().format();
    await this.$store.dispatch('satsurveys/loadData', fromDate);
    this.columnsSatSurveys.forEach((column) => {
      if (column.key === 'unitName') {
        column.filters = this.unitNameFilterOptions;
      }
    });
  }

  onSearch(value: string): void {
    this.$store.dispatch('satsurveys/filterData', { search: value });
  }

  public get tableScrollOpt(): { y: string; x?: number | boolean | string } {
    return { y: this.tableHeight, x: 'max-content' };
  }

  private get tableHeight(): string {
    const headerHeight = 180;
    const footerHeight = 50;
    const pagingHeight = 20;
    return `calc(${window.innerHeight}px - ${headerHeight + footerHeight + pagingHeight}px)`;
  }

  handleNotesChange(unitId: string, date: string, analtyicType: string, value: string): void {
    API.updateSatSurvey(unitId, date, { analyticType: analtyicType, notes: value });
  }

  getSuccessfulAmount(satSurvey: SatSurvey): number {
    return satSurvey.processed - satSurvey.failed - satSurvey.skipped;
  }

  async showModal(satSurvey: SatSurvey): Promise<void> {
    const { unitId, date, analyticType, season, processingType } = satSurvey;
    this.selectedSatSurvey = satSurvey;

    switch (analyticType) {
      case SatAnalyticName.nematodes:
        this.selectedSatSurveyData = await API.getNematodesReportCSV(unitId, date);
        break;
      case SatAnalyticName.sugar:
        this.selectedSatSurveyData = await API.getSatSugarReport(unitId, date, season);
        break;
      case SatAnalyticName.flowering:
        this.selectedSatSurveyData = await API.getFloweringRiskReport(unitId);
        break;
      case SatAnalyticName.cropClassification:
        if (processingType == 4) {
          this.selectedSatSurveyData = await API.getCropClassificationReport(unitId, date);
        }
        break;
      default:
        break;
    }

    this.modalVisible = true;
  }

  handleOk(): void {
    this.selectedSatSurveyData = null;
    this.selectedSatSurvey = null;
    this.modalVisible = false;
  }

  handleCancel(): void {
    this.selectedSatSurveyData = null;
    this.selectedSatSurvey = null;
    this.modalVisible = false;
  }

  async exportReport(analyticType: SatAnalyticName, unitId: string, date?: string): Promise<void> {
    switch (analyticType) {
      case SatAnalyticName.flowering:
        await API.downloadFloweringRiskReportCSV(unitId);
        break;
      default:
        break;
    }
  }

  copyVMName(survey: SatSurvey) {
    this.$copyText(
      `sat-${survey.analyticType}-${survey.unitId}-${survey.date}${survey.season ? `-` + survey.season : ''}`
    );
  }
}
